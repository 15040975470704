import React from "react";
import "../Styles/Footer.css";
import "font-awesome/css/font-awesome.css";
import { Link } from "react-router-dom";
import LOGO from "../assets/logo.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-left">
        <img src={LOGO} alt="Logo" />
        <p>
          Misyonumuz, müşterilerimize yüksek kaliteli kauçuk ve sızdırmazlık
          çözümleri sunarak, işlerinin sürekliliğini ve verimliliğini
          arttırmaktır. Amacımız, müşteri memnuniyetini en üst düzeyde tutarak,
          sektörde lider bir konuma sahip olmaktır.
        </p>
      </div>
      <div className="footer-center">
        <p>Hızlı Erişim</p>
        <ul>
          <li>
            <Link to="/">Ana Sayfa</Link>
          </li>
          <li>
            <Link to="/iletisim">İletişim</Link>
          </li>
          <li>
            <Link to="/urunler">Ürünler</Link>
          </li>
        </ul>
      </div>
      <div className="footer-right">
        <p>
          <i className="fa fa-phone"></i> 0507 135 66 54
        </p>
        <p>
          <i className="fa fa-phone"></i> 0506 117 91 71
        </p>
        <p>
          <i className="fa fa-envelope"></i> detassizdirmalik@hotmail.com
        </p>
        <p>
          <i className="fa fa-map-pin"></i> İvedik OSB. Mah. Havalandırmacılar
          Cad. (KA-CL İş Merkezi) 165/49 Yenimahalle/Ankara
        </p>
      </div>
    </footer>
  );
}

export default Footer;
