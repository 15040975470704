import React, { useState } from "react";
import "../Styles/Areas.css";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import OG from "../assets/o-ring.png";
import YK from "../assets/yag-keceleri.jpg";
import BB from "../assets/bushing.jpg";
import PSTN from "../assets/pistonlar.png";
import ROD from "../assets/rodlar.png";
import YTK from "../assets/yataklamalar.png";
import PR from "../assets/pnomatik-rekorlar.jpg";
import KP from "../assets/kaplin.png";
import PH from "../assets/pnomatikhortum.png";
import TK from "../assets/tozkeceleri.png";
import OSG from "../assets/oring-setleri.jpg";
import TKZ from "../assets/takozlar.jpeg"

const areasInfo = [
  {
    id: 0,
    icon: <img src={OG} alt="o-ring grubu" />,
    description: ["O-RING GRUBU"],
    details: [
      "70 SHR NBR",
      "90 SHR NBR",
      "VİTON (FKM)",
      "SLİKON",
      "ŞERİT",
      "X-RİNG",
      "V-RİNG",
      "PERFLEX - KÖR TAPA CONTASI",
      "PULLU ORİNG",
      "BAKIR PUL",
      "ORİNG DESTEĞİ",
    ],
  },
  {
    id: 1,
    icon: <img src={OSG} alt="o-ring setleri grubu" />,
    description: ["O-RING SETLERİ GRUBU"],
    details: [
      "70 Shore Setler",
      "90 Shore Setler",
      "Vilton Setler",
      "Slikon Setler",
      "İş Makinesi Setleri",
    ],
  },
  {
    id: 2,
    icon: <img src={YK} alt="yağ keçeleri grubu" />,
    description: ["YAĞ KEÇELERİ GRUBU"],
    details: [
      "POMPA KEÇELERİ",
      "NBR YAĞ KEÇELERİ",
      "VİTON(FKM) YAĞ KEÇELERİ",
      "NOK YAĞ KEÇELERİ",
      "CORTECO YAĞ KEÇELERİ",
    ],
  },
  {
    id: 3,
    icon: <img src={BB} alt="burç bushing grubu" />,
    description: ["BURÇ(BUSHİNG) GRUBU"],
    details: [
      "SF-1",
      "SF-2",
      "SF-1B",
      "SF-1F",
      "SF-1W",
      "FB-90",
      "FB-92",
      "FB-90F",
    ],
  },
  {
    id: 4,
    icon: <img src={PSTN} alt="piston keçe grubu" />,
    description: ["PİSTON KEÇE GRUBU"],
    details: [
      "Piston Buffer",
      "T-Seal",
      "GSD",
      "DAQ",
      "SPG",
      "DÖNER MİL KEÇESİ",
      "O.K PİSTON KEÇESİ",
      "KATO PİSTON KEÇESİ",
      "KOMPAKTSET",
      "PNÖMATİK PİSTON KEÇESİ",
    ],
  },
  {
    id: 5,
    icon: <img src={ROD} alt="rod keçe grubu" />,
    description: ["ROD KEÇE GRUBU"],
    details: [
      "NOK",
      "SİMRİT",
      "VİTON NUTRİNG",
      "ROD BUFFER",
      "GSI",
      "GRS",
      "DÖNER TAKSİMAT",
      "ROİ",
    ],
  },
  {
    id: 6,
    icon: <img src={TK} alt="toz keçe grubu" />,
    description: ["TOZ KEÇE GRUBU"],
    details: [
      "KOMATSU TOZ",
      "GRESS (ÇAKMA) TOZ",
      "CAT TOZ",
      "PU TOZ",
      "NBR TOZ",
    ],
  },
  {
    id: 7,
    icon: <img src={YTK} alt="yataklama grubu" />,
    description: ["YATAKLAMA GRUBU"],
    details: [
      "FİBER",
      "FENOLİK",
      "YANMAZ YATAK",
      "DELRİN YATAK",
      "ŞERİT BONZ",
      "ŞERİT FONELİK",
      "KLAVUZ YATAK",
    ],
  },
  {
    id: 8,
    icon: <img src={PH} alt="pnomatik hortumlar" />,
    description: ["PNÖMATİK HORTUMLAR"],
    details: ["POLİÜRETAN", "POLYAMİD HORTUM", "PTFE HORTUM"],
  },
  {
    id: 9,
    icon: <img src={PR} alt="pnomatik rakorlar" />,
    description: ["PNOMATİK RAKORLAR"],
    details: ["METAL RAKOR", "PNÖMATİK RAKOR"],
  },
  {
    id: 10,
    icon: <img src={KP} alt="kaplin grubu" />,
    description: ["KAPLİN GRUBU"],
    details: ["İŞ MAKINASI KAPLİNLERİ", "GR", "MT", "FLENDER", "SBT"],
  },
  {
    id: 10,
    icon: <img src={TKZ} alt="takozlar" />,
    description: ["TAKOZLAR"],
    details: [""],
  },
];

const Areas = () => {
  const [visible, setVisible] = useState(false);
  const [currentArea, setCurrentArea] = useState({});

  const show = (area) => {
    setCurrentArea(area);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  return (
    <div className="Areas">
      <span
        style={{
          fontSize: "4vh",
          paddingBottom: "5px",
          fontFamily: "open sans",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Ürünler
      </span>
      <div
        style={{
          backgroundColor: "#002141",
          margin: "auto",
          maxWidth: "350px",
          padding: "20px",
          borderRadius: "10px",
          color: "red",
          textAlign: "center",
          marginTop: "0",
          marginBottom: "5vh",
        }}
      >
        Detaylı bilgi için ürün görseline tıklayınız
      </div>
      <div className="areas-container">
        {areasInfo.map((area, index) => (
          <div className="area-info" key={index} onClick={() => show(area)}>
            {area.icon}
            <p>{area.description}</p>
          </div>
        ))}
      </div>
      <div>
        <Rodal
          visible={visible}
          onClose={hide}
          customStyles={{ height: "400px" }}
        >
          <div>
            <h2>{currentArea.description}</h2>
            {currentArea.details &&
              currentArea.details.map((detail, index) => (
                <p
                  key={index}
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  {detail}
                </p>
              ))}
          </div>
        </Rodal>
      </div>
    </div>
  );
};

export default Areas;
