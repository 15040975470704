import React from "react";
import "../Styles/Main.css";
import ReactTyped from "react-typed";
import MainSlider from "./MainSlider";

const Main = () => {
  return (
    <div>
      <div className="MainContainer">
        <div>
          <div className="Main" style={{ fontSize: "5rem" }}>
            DETAŞ
          </div>
          <div
            className="Main"
            style={{
              lineHeight: "50.4px",
              letterSpacing: "-0.3px",
              backgroundColor: "#002141",
              color: "#FFFFFF",
            }}
          >
            SIZDIRMAZLIK ELEMANLARI
          </div>
          <div className="Main">MAKİNE SANAYİ TİC. LTD. ŞTİ.</div>
          <div>
            <ReactTyped
              strings={["HIGH QUALITY REPAIR KITS..."]}
              cursorChar=""
              style={{
                fontSize: "35px",
                fontFamily: "Century Gothic",
                color: "#DB3730",
                fontWeight: "bold",
              }}
              typeSpeed={100}
              backSpeed={100}
              loop
            />
          </div>
        </div>

        <img src="excavator.png" alt="Excavator" className="MainImage" />
      </div>
      <MainSlider />
    </div>
  );
};

export default Main;
