import React from "react";
import "../Styles/Contact.css";
import PhoneIcon from "../assets/bx-phone.png";
import MailIcon from "../assets/bx-mail.png";
import MapIcon from "../assets/bx-map.png";

const Contact = () => {
  const contactInfo = [
    {
      type: "Telefon",
      icon: <img src={PhoneIcon} alt="phone icon" />,
      info: ["0507 135 66 54", "0506 117 91 71"],
    },
    {
      type: "E-Posta",
      icon: <img src={MailIcon} alt="mail icon" />,
      info: ["detassizdirmalık@hotmail.com"],
    },
    {
      type: "Adres",
      icon: <img src={MapIcon} alt="adres icon" />,
      info: [
        "İvedik OSB. Mah. Havalandırmacılar Cad.",
        "(KA-CL İş Merkezi)",
        "165/49 Yenimahalle/Ankara",
      ],
    },
  ];

  return (
    <div className="Contact">
      <span
        style={{
          display: "block",
          fontSize: "4vh",
          paddingBottom: "10px",
          fontFamily: "open sans",
          fontWeight: "bold",
          margin: "0 auto",
        }}
      >
        İletişim Bilgileri
      </span>
      <iframe
        className="iframe"
        style={{ position: "center" }}
        title="Google Maps"
        width="600"
        height="450"
        loading="lazy"
        allowfullscreen
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.476625814147!2d32.74315531525705!3d39.997799979415994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34bea09353e35%3A0x66d5713437021ea8!2zRGV0YcWfIFPEsXpkxLFybWF6bMSxayBFbGVtYW5sYXLEsQ!5e0!3m2!1str!2str!4v1676894858400!5m2!1str!2str"
      />

      <div className="contact-container">
        {contactInfo.map((item, index) => (
          <div className="contact-box" key={index}>
            <h3>{item.type}</h3>
            <ul>
              <li>{item.icon}</li>
              {item.info.map((info, index) => (
                <li key={index}>{info}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;
