import React from "react";
import LOGO from "../assets/icerik.jpeg";
import "../Styles/Products.css";

const Products = () => {
  return (
    <div>
      <img className="icerik" src={LOGO} alt="icerik" />
    </div>
  );
};

export default Products;
