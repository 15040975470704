import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/Navbar.css";
import LOGO from "../assets/logo.png";

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const [navBackground, setNavBackground] = useState("rgba(0, 33, 65, 1)");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const navbarHeight = document.querySelector(".nav").offsetHeight;
      if (currentScrollY > navbarHeight) {
        setNavBackground("rgba(0, 33, 65, 0.8)");
      } else {
        setNavBackground("rgba(0, 33, 65, 1)");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

  return (
    <nav className="nav" style={{ backgroundColor: navBackground }}>
      <Link to="/" className="nav__brand">
        <img src={LOGO} alt="Logo" />
      </Link>
      <ul className={active}>
        <li className="nav__item">
          <Link to="/" className="nav__link" style={{ color: "#FFFFFF" }}>
            Anasayfa
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/urunler"
            className="nav__link"
            style={{ color: "#FFFFFF" }}
          >
            Ürünler
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/faaliyet-alanlari"
            className="nav__link"
            style={{ color: "#FFFFFF" }}
          >
            Faaliyet Alanları
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/iletisim"
            className="nav__link"
            style={{ color: "#FFFFFF" }}
          >
            İletişim
          </Link>
        </li>
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;
