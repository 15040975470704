import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./Components/Contact";
import Main from "./Components/Main";
import Areas from "./Components/Areas";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Products from "./Components/Products";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/urunler" element={<Areas />} />
        <Route path="/faaliyet-alanlari" element={<Products />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
