import React, { Component } from "react";
import Slider from "react-slick";
import "../Styles/MainSlider.css";
import Cat from "../assets/Cat.png";
import Sumitomo from "../assets/Sumitomo.png";
import Schwing from "../assets/Schwing.png";
import zoomlion from "../assets/Zoomlion.png";
import Volvo from "../assets/Volvo.png";
import putzmeister from "../assets/Putzmeister.png";
import Jcb from "../assets/Jcb.png";
import Liebherr from "../assets/Liebherr.jpeg";
import Kawasaki from "../assets/Kawasaki.png";
import komatsu from "../assets/Komatsu.png";
import Hitachi from "../assets/Hitachi.png";
import Hyundai from "../assets/Hyundai.png";
import Case from "../assets/Case.png";
import Cifa from "../assets/Cifa.png";
import Doosan from "../assets/Doosan.png";
import Hidromek from "../assets/Hidromek.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class MainSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 2,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 100,
      cssEase: "linear",
      centerMode: true,
      centerPadding: "0px",
      swipe: true,
    };
    return (
      <div className="mainContainer">
        <Slider {...settings}>
          <div className="container">
            <img src={Cat} alt="cat logo" />
          </div>
          <div className="container">
            <img src={Case} alt="case logo" />
          </div>
          <div className="container">
            <img src={Cifa} alt="cifa logo" />
          </div>
          <div className="container">
            <img src={Doosan} alt="Doosan logo" />
          </div>
          <div className="container">
            <img src={Hidromek} alt="Hidromek logo" />
          </div>
          <div className="container">
            <img src={Hitachi} alt="Hitachi logo" />
          </div>
          <div className="container">
            <img src={Hyundai} alt="cat logo" />
          </div>
          <div className="container">
            <img src={Jcb} alt="jcb logo" />
          </div>
          <div className="container">
            <img src={Kawasaki} alt="kawasaki logo" />
          </div>
          <div className="container">
            <img src={komatsu} alt="komatsu logo" />
          </div>
          <div className="container">
            <img src={Liebherr} alt="liebherr logo" />
          </div>
          <div className="container">
            <img src={putzmeister} alt="putzmeister logo" />
          </div>
          <div className="container">
            <img src={Volvo} alt="volvo logo" />
          </div>
          <div className="container">
            <img src={zoomlion} alt="zoomlion logo" />
          </div>
          <div className="container">
            <img src={Sumitomo} alt="sumitomo logo" />
          </div>
          <div className="container">
            <img src={Schwing} alt="schwing logo" />
          </div>
        </Slider>
      </div>
    );
  }
}

export default MainSlider;
